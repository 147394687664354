<template>
  <div>
    <ed-periodico-configuracao
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdPeriodicoConfiguracao from "@/components/periodico/configuracao/editar";



export default {
  name: "pageCadastro",
  props: {},
  components:{EdPeriodicoConfiguracao},
  mounted() {
  },
  created() {


  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>

