<template>
  <div v-if="formData && formData.configuracaoPeriodico.strValor">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Básicas</span>

        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.configuracaoPeriodico.strValor.arrayEmpresaDesativada"
            label="Desativar Envio para as Empresas"
            name="arrayEmpresaEmail"
            item-text="strNome"
            route="Cadastro/CadastroGeral"
            :filters="{ intTipoCadastroGeralId: 58,strBusca:(formData.configuracaoPeriodico.strValor.arrayEmpresaDesativada.length ? formData.configuracaoPeriodico.strValor.arrayEmpresaDesativada : null) }"
            :initializeItems="formData.configuracaoPeriodico.strValor.arrayEmpresaDesativada.length  ? true : false"
            clearable
            multiple
          />
<!-- 
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.configuracaoPeriodico.strValor.arrayUnidadeDesativada"
            label="Desativar Envio para as Unidades"
            name="arrayEmpresaEmail"
            item-text="strNome"
            route="Cadastro/CadastroGeralUnidade"
            :disabled="!formData.configuracaoPeriodico.strValor.arrayEmpresaDesativada.length"
            :filters="{ strBusca:(formData.configuracaoPeriodico.strValor.arrayUnidadeDesativada.length ? formData.configuracaoPeriodico.strValor.arrayUnidadeDesativada : null) }"
            :initializeItems="formData.configuracaoPeriodico.strValor.arrayUnidadeDesativada.length  ? true : false"
            clearable
            multiple
          /> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Template</span>

        <div class="row">
          <ed-input-editor
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Modelo  da Lista de Convocação Enviada por Email"
            name="strHorarioAtendimentoFinal"
            v-model="formData.configuracaoPeriodico.strValor.strHtmlListaConvovacao"
            rules="required"
            extraPlugins="edol"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputSelect,
  EdInputDate,
  EdInputTime,
  EdInputEditor,
  EdInputAutoComplete,
  EdButton,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputSelect,
    EdInputDate,
    EdInputTime,
    EdInputEditor,
    EdInputAutoComplete,
    EdButton,
  },
  inject:['bind'],
  provide() {
    return {};
  },
  mounted() {

  },
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
  },
};
</script>
